import styled from 'styled-components';

import ImgConfetti from './confetti.svg';

export const BadgeAchieveContainerStyled = styled.div`
  display: flex;
  position: relative;
  padding: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageGroupStyled = styled.div`
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${ImgConfetti}) center no-repeat;
  background-size: contain;
  background-position: bottom;
  @media screen and (max-width: 768px) {
    padding-bottom: 20px;
  }
  img {
    max-width: 80%;
    height: 80%;
    object-fit: contain;
    animation: bounceUpDown 1s ease-in-out infinite;
    @media screen and (max-width: 768px) {
      width: 50%;
      height: 50%;
    }
  }
  @keyframes bounceUpDown {
    0%, 100% {
      transform: translate(0, 0);
    }
  
    50% {
      transform: translate(0, 5px);
    }
  }
`;

export const TextGroupStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 769px) {
    padding-left: 20px;
  }
  @media screen and (max-width: 768px) {
    align-items: center;
  }
  label {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }
  p {
    color: #343434;
    font-size: 14px;
    margin-bottom: 20px;
  }
  button {
    padding: 10px 20px;
    border-radius: 25px;
    max-width: 160px;
    font-size: 14px;
  }
`;

export const CloseStyled = styled.div`
  position: absolute;
  font-size: 16px;
  cursor: pointer;
  top: -15px;
  right: -15px;
  padding: 5px;
  color: #979797;
  @media screen and (max-width: 768px) {
    top: 0;
    right: 0;
  }
`;

export const ShareStyled = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr;
  border-radius: 20px;
  overflow: hidden;
  margin: 20px 0;
  background-color: #1d39a9;
  max-width: 180px;
  button {
    background-color: transparent;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 13px;
    font-weight: 600;
    color: white;
    &:nth-child(2) {
      background-color: #1da1f2;
      border-radius: 0;
    }
  }
`;
