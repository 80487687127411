import { camelizeKeys } from 'src/utils/object';
import { GET_BADGE_REPORT, SHOW_BADGE_ACHIEVE_MODAL, HIDE_BADGE_ACHIEVE_MODAL } from './type';

const initialState = {
  report: null,
  achieveModal: {
    show: false,
    badge: null,
  }
};
export default (state = initialState, action) => {
  switch(action.type) {
    case `${GET_BADGE_REPORT}_SUCCESS`: {
      const mapBadge = {};
      camelizeKeys(action.data).forEach(item => {
        mapBadge[item.type] = item;
      });
      return {
        ...state,
        report: mapBadge,
      };
    }
    case SHOW_BADGE_ACHIEVE_MODAL:
      return {
        ...state,
        achieveModal: {
          show: true,
          badge: action.badge,
        }
      };
    case HIDE_BADGE_ACHIEVE_MODAL:
      return {
        ...state,
        achieveModal: initialState.achieveModal,
      };
    default:
      return state;
  }
};
