import Newbie from 'src/assets/images/badges/newbie.svg';
import NewbieInactive from 'src/assets/images/badges/newbie_inactive.svg';
import Member from 'src/assets/images/badges/member.svg';
import MemberInactive from 'src/assets/images/badges/member_inactive.svg';
import Guardian from 'src/assets/images/badges/guardian.svg';
import GuardianInactive from 'src/assets/images/badges/guardian_inactive.svg';
import Icebreaker from 'src/assets/images/badges/icebreaker.svg';
import IcebreakerInactive from 'src/assets/images/badges/icebreaker_inactive.svg';
import Supporter from 'src/assets/images/badges/supporter.svg';
import SupporterInactive from 'src/assets/images/badges/supporter_inactive.svg';
import Promoter from 'src/assets/images/badges/promoter.svg';
import PromoterInactive from 'src/assets/images/badges/promoter_inactive.svg';
import InvestorBronze from 'src/assets/images/badges/investor_bronze.svg';
import InvestorBronzeInactive from 'src/assets/images/badges/investor_bronze_inactive.svg';
import InvestorSilver from 'src/assets/images/badges/investor_silver.svg';
import InvestorSilverInactive from 'src/assets/images/badges/investor_silver_inactive.svg';
import InvestorGold from 'src/assets/images/badges/investor_gold.svg';
import InvestorGoldInactive from 'src/assets/images/badges/investor_gold_inactive.svg';
import BorrowerBronze from 'src/assets/images/badges/borrower_bronze.svg';
import BorrowerBronzeInactive from 'src/assets/images/badges/borrower_bronze_inactive.svg';
import BorrowerSilver from 'src/assets/images/badges/borrower_silver.svg';
import BorrowerSilverInactive from 'src/assets/images/badges/borrower_silver_inactive.svg';
import BorrowerGold from 'src/assets/images/badges/borrower_gold.svg';
import BorrowerGoldInactive from 'src/assets/images/badges/borrower_gold_inactive.svg';
import Dealmaker from 'src/assets/images/badges/dealmaker.svg';
import DealmakerInactive from 'src/assets/images/badges/dealmaker_inactive.svg';
import Patron from 'src/assets/images/badges/patron.svg';
import PatronInactive from 'src/assets/images/badges/patron_inactive.svg';
import InfluencerBronze from 'src/assets/images/badges/influencer_bronze.svg';
import InfluencerBronzeInactive from 'src/assets/images/badges/influencer_bronze_inactive.svg';
import InfluencerSilver from 'src/assets/images/badges/influencer_silver.svg';
import InfluencerSilverInactive from 'src/assets/images/badges/influencer_silver_inactive.svg';
import InfluencerGold from 'src/assets/images/badges/influencer_gold.svg';
import InfluencerGoldInactive from 'src/assets/images/badges/influencer_gold_inactive.svg';
import HeadhunterBronze from 'src/assets/images/badges/headhunter_bronze.svg';
import HeadhunterBronzeInactive from 'src/assets/images/badges/headhunter_bronze_inactive.svg';
import HeadhunterSilver from 'src/assets/images/badges/headhunter_silver.svg';
import HeadhunterSilverInactive from 'src/assets/images/badges/headhunter_silver_inactive.svg';
import HeadhunterGold from 'src/assets/images/badges/headhunter_gold.svg';
import HeadhunterGoldInactive from 'src/assets/images/badges/headhunter_gold_inactive.svg';
import LuminaryBronze from 'src/assets/images/badges/luminary_bronze.svg';
import LuminaryBronzeInactive from 'src/assets/images/badges/luminary_bronze_inactive.svg';
import LuminarySilver from 'src/assets/images/badges/luminary_silver.svg';
import LuminarySilverInactive from 'src/assets/images/badges/luminary_silver_inactive.svg';
import LuminaryGold from 'src/assets/images/badges/luminary_gold.svg';
import LuminaryGoldInactive from 'src/assets/images/badges/luminary_gold_inactive.svg';
import Financier from 'src/assets/images/badges/financier.svg';
import FinancierInactive from 'src/assets/images/badges/financier_inactive.svg';
import Fundraiser from 'src/assets/images/badges/fundraiser.svg';
import FundraiserInactive from 'src/assets/images/badges/fundraiser_inactive.svg';
import StalwartBronze from 'src/assets/images/badges/stalwart_bronze.svg';
import StalwartBronzeInactive from 'src/assets/images/badges/stalwart_bronze_inactive.svg';
import StalwartSilver from 'src/assets/images/badges/stalwart_silver.svg';
import StalwartSilverInactive from 'src/assets/images/badges/stalwart_silver_inactive.svg';
import StalwartGold from 'src/assets/images/badges/stalwart_gold.svg';
import StalwartGoldInactive from 'src/assets/images/badges/stalwart_gold_inactive.svg';
import Champion from 'src/assets/images/badges/champion.svg';
import ChampionInactive from 'src/assets/images/badges/champion_inactive.svg';
import MembershipGold from 'src/screens/memberShip/imgs/gold.svg';
import MembershipPlatinum from 'src/screens/memberShip/imgs/platinum.svg';
import MembershipDiamond from 'src/screens/memberShip/imgs/diamond.svg';
import Enthusiast from 'src/assets/images/badges/enthusiast.svg';
import Expert from 'src/assets/images/badges/expert.svg';
import GadgeTeer from 'src/assets/images/badges/gadgeteer.svg';
import Saver from 'src/assets/images/badges/saver.svg';
import Vlogger from 'src/assets/images/badges/vlogger.svg';
import Booster from 'src/assets/images/badges/booster.svg';
import DailyLogin from 'src/assets/images/badges/daily_login.svg';
import Appster from 'src/assets/images/badges/appster.svg';
import Leader from 'src/assets/images/badges/leader.svg';
import Consumer from 'src/assets/images/badges/consumer.png';
import Harbinger from 'src/assets/images/badges/harbinger.png';
import Spender from 'src/assets/images/badges/spender.png';
import McttokenFirstHolder from 'src/assets/images/badges/mcttoken_first_holder.png';
import McttokenFirstHolderInactive from 'src/assets/images/badges/mcttoken_first_holder_inactive.png';
import McttokenFirstSwap from 'src/assets/images/badges/mcttoken_first_swap.svg';
import McttokenFirstSwapInactive from 'src/assets/images/badges/mcttoken_first_swap_inactive.svg';
import { BADGE_ITEM_KEYS } from './constant';

export const getBadgeImage = (key, active = true) => {
  switch (key) {
    case BADGE_ITEM_KEYS.SIGN_UP:
      return active ? Newbie : NewbieInactive;
    case BADGE_ITEM_KEYS.KYC:
      return active ? Member : MemberInactive;
    case BADGE_ITEM_KEYS.TWO_FA:
      return active ? Guardian : GuardianInactive;
    case BADGE_ITEM_KEYS.FIRST_DEPOSIT:
      return active ? Icebreaker : IcebreakerInactive;
    case BADGE_ITEM_KEYS.REVIEW_TRUSTPILOT:
      return active ? Supporter : SupporterInactive;
    case BADGE_ITEM_KEYS.FIRST_FRIEND:
      return active ? Promoter : PromoterInactive;
    case BADGE_ITEM_KEYS.FIRST_INVEST:
      return active ? InvestorBronze : InvestorBronzeInactive;
    case BADGE_ITEM_KEYS.INVEST_MONTHLY_QUARTER:
      return active ? InvestorSilver : InvestorSilverInactive;
    case BADGE_ITEM_KEYS.INVEST_MONTHLY_YEAR:
      return active ? InvestorGold : InvestorGoldInactive;
    case BADGE_ITEM_KEYS.FIRST_BORROW:
      return active ? BorrowerBronze : BorrowerBronzeInactive;
    case BADGE_ITEM_KEYS.BORROW_MONTHLY_QUARTER:
      return active ? BorrowerSilver : BorrowerSilverInactive;
    case BADGE_ITEM_KEYS.BORROW_MONTHLY_YEAR:
      return active ? BorrowerGold : BorrowerGoldInactive;
    case BADGE_ITEM_KEYS.SELL_SECONDARY_MARKET:
      return active ? Dealmaker : DealmakerInactive;
    case BADGE_ITEM_KEYS.BUY_SECONDARY_MARKET:
      return active ? Patron : PatronInactive;
    case BADGE_ITEM_KEYS.ARTICLE_FIRST:
      return active ? InfluencerBronze : InfluencerBronzeInactive;
    case BADGE_ITEM_KEYS.ARTICLE_MONTHLY_QUARTER:
      return active ? InfluencerSilver : InfluencerSilverInactive;
    case BADGE_ITEM_KEYS.ARTICLE_MONTHLY_YEAR:
      return active ? InfluencerGold : InfluencerGoldInactive;
    case BADGE_ITEM_KEYS.REFER_10_FRIENDS:
      return active ? HeadhunterBronze : HeadhunterBronzeInactive;
    case BADGE_ITEM_KEYS.REFER_50_FRIENDS:
      return active ? HeadhunterSilver : HeadhunterSilverInactive;
    case BADGE_ITEM_KEYS.REFER_100_FRIENDS:
      return active ? HeadhunterGold : HeadhunterGoldInactive;
    case BADGE_ITEM_KEYS.REFERRING_INVEST_100K:
      return active ? LuminaryBronze : LuminaryBronzeInactive;
    case BADGE_ITEM_KEYS.REFERRING_INVEST_200K:
      return active ? LuminarySilver : LuminarySilverInactive;
    case BADGE_ITEM_KEYS.REFERRING_INVEST_500K:
      return active ? LuminaryGold : LuminaryGoldInactive;
    case BADGE_ITEM_KEYS.WINNING_INVEST:
      return active ? Financier : FinancierInactive;
    case BADGE_ITEM_KEYS.WINNING_BORROW:
      return active ? Fundraiser : FundraiserInactive;
    case BADGE_ITEM_KEYS.LOYALTY_6_MONTHS:
      return active ? StalwartBronze : StalwartBronzeInactive;
    case BADGE_ITEM_KEYS.LOYALTY_12_MONTHS:
      return active ? StalwartSilver : StalwartSilverInactive;
    case BADGE_ITEM_KEYS.LOYALTY_24_MONTHS:
      return active ? StalwartGold : StalwartGoldInactive;
    case BADGE_ITEM_KEYS.CHAMPION:
      return active ? Champion : ChampionInactive;
    case BADGE_ITEM_KEYS.MCTTOKEN_FIRST_HOLDER:
      return active ? McttokenFirstHolder : McttokenFirstHolderInactive;
    case BADGE_ITEM_KEYS.MCTTOKEN_FIRST_SWAP:
      return active ? McttokenFirstSwap : McttokenFirstSwapInactive;
    case BADGE_ITEM_KEYS.MEMBERSHIP_GOLD:
      return MembershipGold;
    case BADGE_ITEM_KEYS.MEMBERSHIP_PLATINUM:
      return MembershipPlatinum;
    case BADGE_ITEM_KEYS.MEMBERSHIP_DIAMOND:
      return MembershipDiamond;
    case BADGE_ITEM_KEYS.INSTALL_APP:
      return GadgeTeer;
    case BADGE_ITEM_KEYS.PASS_MINI_QUIZ:
      return Expert;
    case BADGE_ITEM_KEYS.DEPOSIT_3_MONTH:
      return Saver;
    case BADGE_ITEM_KEYS.SECONDARY_MISS:
      return Enthusiast;
    case BADGE_ITEM_KEYS.CREATE_VIDEO:
      return Vlogger;
    case BADGE_ITEM_KEYS.LINK_BANK:
      return Booster;
    case BADGE_ITEM_KEYS.REVIEW_APP:
      return Appster;
    case BADGE_ITEM_KEYS.REFEREE_PASS_KYC:
      return Leader;
    case BADGE_ITEM_KEYS.DEBIT_CARD_FIRST_CREATED:
      return Consumer;
    case BADGE_ITEM_KEYS.DEBIT_CARD_FIRST_ADD_FUND:
      return Harbinger;
    case BADGE_ITEM_KEYS.DEBIT_CARD_FIRST_TRANSACTION:
      return Spender;
    default:
      return null;
  }
};
