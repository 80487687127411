import React from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import { API_ROOT, URL } from 'src/resources/constants/url';
import IconFb from 'src/assets/images/badges/ic_fb.svg';
import IconTwitter from 'src/assets/images/badges/ic_twitter.svg';
import { getBadgeImage } from 'src/screens/badges/images';
import { LabelLang } from 'src/lang/components';
import badgeReducer from 'src/screens/badges/redux/reducer';
import withReducer from 'src/hocs/hocWithReducer';

import { hideBadgeAchieveModal } from './action';
import { BadgeAchieveContainerStyled, ImageGroupStyled, TextGroupStyled, CloseStyled, ShareStyled } from './styled';


const BadgeAchieveModal = props => {
  const { show, fullName, badge, hideBadgeAchieveModal } = props;

  const handleShareFb = e => {
    e.stopPropagation();
    const url = `${API_ROOT}${URL.BADGES}`;
    const quote = fullName ? `${fullName} has achieved a new badge on MyConstant.\n Claim yours now!` : '';
    FB.ui({
      method: 'share',
      href: url,
      quote,
    });
  };

  const handleShareTwitter = e => {
    e.stopPropagation();
    const url = encodeURIComponent(`${API_ROOT}${URL.BADGES}`);
    const quote = fullName ? `${fullName} has achieved a new badge on MyConstant.\n Claim yours now!` : '';
    window.open(`https://twitter.com/intent/tweet/?quote=$${quote}&url=${url}`, '_blank');
  };

  return (
    <Modal show={show} centered keyboard={false}>
      <Modal.Body>
        {badge && (
          <BadgeAchieveContainerStyled>
            <ImageGroupStyled>
              <img alt="" src={getBadgeImage(badge.type)} />
            </ImageGroupStyled>
            <TextGroupStyled>
              <label>Congratulations!</label>
              <p><LabelLang id={`badges.congratulations.${badge.type}`} values={{ rewardAmount: badge.rewardAmount }} /></p>
              <ShareStyled>
                <button type="button" onClick={handleShareFb}>
                  SHARE
                  <img alt="fb" src={IconFb} />
                </button>
                <button type="button" onClick={handleShareTwitter}><img alt="twitter" src={IconTwitter} /></button>
              </ShareStyled>
            </TextGroupStyled>
            <CloseStyled onClick={hideBadgeAchieveModal}><i className="fa fa-times" /></CloseStyled>
          </BadgeAchieveContainerStyled>
        )}
      </Modal.Body>
    </Modal>
  );
};

const mapState = state => {
  return {
    show: state.badges?.achieveModal.show,
    badge: state.badges?.achieveModal.badge,
    systemBadges: state.app.badges,
    fullName: state.auth.profile?.fullName,
  };
};

const mapDispatch = {
  hideBadgeAchieveModal,
};

export default compose(
  connect(mapState, mapDispatch),
  withReducer('badges', badgeReducer),
  withRouter,
)(BadgeAchieveModal);
